import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'

const HomePage = () => (
  <Layout>
    <main id="page-home">
      <section className="banner banner1">
        <div className="container">
          <div className="col-sm-8 message">
            <h1>
              Tomi is powering down,
              <br /> but you can power up!
            </h1>

            <div>
              <a
                className="btn btn-lg btn-feature"
                href="https://www.superloop.com/internet/nbn"
                target="_self"
              >
                Switch to Superloop plan from{' '}
                <strong>
                  $59/mth
                  <sup>*</sup>
                </strong>
              </a>
            </div>

            <small className="mt-3">
              *For the first 6 months, then $69/mth ongoing. See website for{' '}
              <a href="https://www.superloop.com/terms" target="_blank">
                T&Cs
              </a>
            </small>
          </div>

          <div className="col-sm-4 options">
            <div className="tomi-icon-list tomi-icon-list--large">
              <ul>
                <li>
                  <a
                    href="https://www.superloop.com/flip-to-fibre"
                    target="_self"
                    className="btn btn-lg"
                  >
                    <span className="icon icon-tomi-arrow-right" />
                    See Superloop Plans
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.superloop.com/internet/bundle-and-save"
                    target="_self"
                    className="btn btn-lg"
                  >
                    <span className="icon icon-tomi-search" />
                    Bundle n Save
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="showcase">
        <div className="container">
          <div className="intro">
            <div className="row">
              <div className="col-sm-6">
                <h2>
                  All the features you’d expect.
                  <br />
                  <span className="text-clip">But better.</span>
                </h2>
              </div>
              <div className="col-sm-6">
                <p className="lead-text">
                  We’ve designed our plans and service to reflect what people
                  want from their broadband provider.
                </p>
                <p className="lead-text">
                  Our plans are designed with real people in mind.
                </p>
                <p className="lead-text">
                  And we know real people want flexibility and value for money.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="showcase">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="panel">
                <h3 className="title">Competitive Pricing</h3>
                <p className="body">
                  Our plans are designed with real people in mind. And we know
                  real people want flexibility and value for money.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="panel">
                <h3 className="title">No hidden fees, no surprises</h3>
                <p className="body">
                  Track your usage in real time using our self-service portal
                  and you’ll always know exactly what you’re spending.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="panel">
                <h3 className="title">Fast, friendly support</h3>
                <p className="body">
                  If you need technical help, our team is on-call seven-days a
                  week. We’re always happy to hear from you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="plans">
        <div className="container">
          <h3>
            nbn <em>Unlimited</em> plans
          </h3>

          <div className="row">
            <div className="col-sm-5">
              <div className="plan">
                <div className="price price-circle price-circle-lead-prefix">
                  <p className="lead-text text-bold-secondary">Starting From</p>
                  <div className="amount">
                    $<span>59.95</span>
                    <sup>*</sup>
                  </div>
                </div>
              </div>
              <small
                className="text-fine-print text-center"
                style={{ display: 'block' }}
              >
                *Free ZTE H1600 wifi device on a 24 Month Contract Term
              </small>
            </div>

            <div className="col-sm-7">
              <div className="features">
                <ul className="list list-dot-point">
                  <li>True unlimited</li>
                  <li>No shaping</li>
                  <li>No peak/off peak times</li>
                </ul>
              </div>

              <div className="recommended">
                <strong className="d-inline-block">Best for:</strong>
                <ul className="list list-dot-point">
                  <li>Frequent internet usage</li>
                  <li>Supporting streaming services</li>
                  <li>Gamers</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-5 d-flex flex-column align-items-center">
              <Link
                to="/plans-pricing"
                className="btn btn-gradient btn-inline-block ttu"
              >
                Let&apos;s Get Started
              </Link>
            </div>
          </div>
        </div>
      </section>

      <section className="banner banner2">
        <div className="container">
          <div className="col-md-6">
            <h2 className="title">Introducing Pause Time</h2>
          </div>
          <div className="col-md-6">
            <h3>Going away? Pause your service.</h3>
            <ul>
              <li>You shouldn’t have to pay if you’re away.</li>
              <li>Pause your usage and your billing.</li>
              <li>Turn PauseTime on or off in account settings.</li>
              <li>Just another way we’re helping you save.</li>
            </ul>

            <small>
              PauseTime can be used for a maximum of 28 days per 12-month
              period.
              <br />
              PauseTime is available to Tomi customers on select plans that have
              had an active connection for 3 months or more.
            </small>
          </div>
        </div>
      </section> */}
    </main>
  </Layout>
)

export default HomePage
